import dayjs from 'dayjs'
import { formats } from '../constants/formats'

export const validateDateString = (
  dateString: string,
  isBigNow?: boolean
): string => {
  let dateArr = dateString.split('.')
  let monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

  if (!isBigNow && dateString.length === 10) {
    const today = dayjs(new Date())
    const date = dayjs(dateString, formats.DATE)

    if (!date.isBefore(today)) {
      return today.format(formats.DATE)
    }
  }

  if (dateArr[2] && Number(dateArr[2]) % 4 === 0) monthLength[1] = 29

  if (dateArr[1] && Number(dateArr[1]) > 12) dateArr[1] = '12'

  if (dateArr[0] === '29' && dateArr[1] === '02' && dateArr[2]?.length === 4) {
    dateArr[0] = String(monthLength[Number(dateArr[1]) - 1])
  }

  if (dateArr[0] && dateArr[1]) {
    if (dateArr[0] === '29' && dateArr[1] === '02') {
    } else if (Number(dateArr[0]) > monthLength[Number(dateArr[1]) - 1]) {
      dateArr[0] = String(monthLength[Number(dateArr[1]) - 1])
    }
  }

  return dateArr.join('.')
}
