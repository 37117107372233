import React from 'react'

const DollarIcon: React.FC = () => {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.25 18.8533V25.5267L23.8933 24.71C22.7033 24.29 21.98 23.8933 21.98 21.8633C21.98 20.2067 23.2166 18.8533 24.7333 18.8533H26.25Z"
        fill="#C3C7CE"
      />
      <path
        d="M34.02 34.1367C34.02 35.7933 32.7833 37.1467 31.2667 37.1467H29.75V30.4733L32.1067 31.29C33.2967 31.71 34.02 32.1067 34.02 34.1367Z"
        fill="#C3C7CE"
      />
      <path
        d="M37.7766 4.66667H18.2233C9.72996 4.66667 4.66663 9.73001 4.66663 18.2233V37.7767C4.66663 46.27 9.72996 51.3333 18.2233 51.3333H37.7766C46.27 51.3333 51.3333 46.27 51.3333 37.7767V18.2233C51.3333 9.73001 46.27 4.66667 37.7766 4.66667ZM33.2733 28C35.0933 28.63 37.52 29.96 37.52 34.1367C37.52 37.73 34.72 40.6467 31.2666 40.6467H29.75V42C29.75 42.9567 28.9566 43.75 28 43.75C27.0433 43.75 26.25 42.9567 26.25 42V40.6467H25.41C21.5833 40.6467 18.48 37.4033 18.48 33.4367C18.48 32.48 19.25 31.6867 20.23 31.6867C21.1866 31.6867 21.98 32.48 21.98 33.4367C21.98 35.49 23.52 37.1467 25.41 37.1467H26.25V29.2367L22.7266 28C20.9066 27.37 18.48 26.04 18.48 21.8633C18.48 18.27 21.28 15.3533 24.7333 15.3533H26.25V14C26.25 13.0433 27.0433 12.25 28 12.25C28.9566 12.25 29.75 13.0433 29.75 14V15.3533H30.59C34.4166 15.3533 37.52 18.5967 37.52 22.5633C37.52 23.52 36.75 24.3133 35.77 24.3133C34.8133 24.3133 34.02 23.52 34.02 22.5633C34.02 20.51 32.48 18.8533 30.59 18.8533H29.75V26.7633L33.2733 28Z"
        fill="#C3C7CE"
      />
    </svg>
  )
}

export default DollarIcon
