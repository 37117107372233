import { Button, Form, Input } from 'antd'
import React from 'react'
import { useLogin } from '../../queries/mutations'
import Redirector from '../../routing/Redirector'

import './Login.scss'

type FormFields = {
  login: string
  password: string
}

const Login: React.FC = () => {
  const { Item } = Form
  const [formInstance] = Form.useForm()
  const loginMutation = useLogin(formInstance)

  // login
  const login = (fields: FormFields) => {
    loginMutation.mutate(fields)
  }

  return (
    <div className="login">
      <div className="auth">
        <h1>Kirish</h1>
        <Form
          layout="vertical"
          form={formInstance}
          onFinish={login}
          autoComplete="off"
        >
          <Item
            name="login"
            label="Login"
            rules={[
              {
                required: true,
                message: 'Loginingizni kiriting!',
              },
            ]}
          >
            <Input />
          </Item>
          <Item
            name="password"
            label="Parol"
            rules={[
              {
                required: true,
                message: 'Parolingizni kiriting!',
              },
            ]}
          >
            <Input.Password />
          </Item>
          <Button
            htmlType="submit"
            type="primary"
            className="common_btn"
            loading={loginMutation.isLoading}
          >
            Kirish
          </Button>
        </Form>
      </div>
      <Redirector />
    </div>
  )
}

export default Login
