import { message } from 'antd'
import axios from 'axios'
import { localStorageKeys } from '../utils/constants/localStorageKeys'
import { authService } from '../utils/helpers/authService'
import { rootPaths } from '../utils/routes/rootPaths'

const $api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

$api.interceptors.request.use((config) => {
  const authorization = authService.getToken()
  if (authorization && config.headers) {
    config.headers.Authorization = `Bearer ${authorization}`
  }
  return config
})

$api.interceptors.response.use(undefined, (error) => {
  if (error.message === 'Network Error') {
    message.error("Internet tarmog'iga ulanishda xatolik yuz berdi", 5)
  }
  if (
    error.response?.status === 401 &&
    window.location.pathname !== rootPaths.login
  ) {
    localStorage.removeItem(localStorageKeys.AUTHORIZATION)
    window.location.href = rootPaths.login
  }
  return Promise.reject(error)
})

export default $api
