import { Button, Empty, Modal } from 'antd'
import React from 'react'

import CloseIcon from '../../assets/svg/CloseIcon'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { productReducer } from '../../redux/reducers/productReducer'
import ProductCont from '../productCont/ProductCont'

import './ProductModal.scss'

type props = {
  viewMode: boolean
  clientCurrency: string | undefined
}

const ProductModal: React.FC<props> = ({ viewMode, clientCurrency }) => {
  const { visibleProductModal, products } = useAppSelector(
    (state) => state.productReducer
  )
  const dispatch = useAppDispatch()
  const { setVisibleProductModal } = productReducer.actions

  // close modal
  const closeModal = () => {
    dispatch(setVisibleProductModal(false))
  }

  // on cancel
  const onCancel = () => {
    closeModal()
  }

  // empty
  const empty = () => {
    if (viewMode && products?.length === 0) {
      return <Empty description={<h3 className="ta_c">Mahsulotlar yo'q</h3>} />
    }
    return null
  }

  return (
    <Modal
      visible={visibleProductModal}
      className="product_modal custom_modal"
      closable={false}
      footer={false}
      centered
      onCancel={onCancel}
      zIndex={1001}
    >
      <div className="custom_modal_header">
        <h1>Mahsulotlar</h1>
        <div className="close_icon" onClick={onCancel}>
          <CloseIcon />
        </div>
      </div>
      <div className="product_modal_body">
        {!viewMode && (
          <ProductCont
            product={null}
            clientCurrency={clientCurrency}
            viewMode={viewMode}
          />
        )}
        {products.map((product, index) => (
          <ProductCont
            key={index}
            product={product}
            index={index}
            clientCurrency={clientCurrency}
            viewMode={viewMode}
          />
        ))}
        {empty()}
      </div>
      {!viewMode && (
        <div className="custom_modal_footer">
          <Button className="cancel_btn" onClick={onCancel}>
            Bekor qilish
          </Button>
          <Button type="primary" className="submit_btn" onClick={closeModal}>
            Davom etish
          </Button>
        </div>
      )}
    </Modal>
  )
}

export default ProductModal
