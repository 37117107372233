export const sortType = {
  ASC: 'asc',
  DESC: 'desc',
}

export const sortBy = {
  NAME: 'name',
  BALANCE: 'balance',
  DATE: 'date',
  INCOME: 'income',
  OUTCOME: 'outcome',
}
