import { Button, Grid, Input, Pagination, Table, Tooltip } from 'antd'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import EditIcon from '../../assets/svg/EditIcon'
import NextIcon from '../../assets/svg/NextIcon'
import PrevIcon from '../../assets/svg/PrevIcon'
import SearchIcon from '../../assets/svg/SearchIcon'
import { useClients } from '../../queries/queries'
import { colors } from '../../utils/constants/colors'
import { linkTypes } from '../../utils/constants/linkTypes'
import { locales } from '../../utils/constants/locales'
import { queryParamsKeys } from '../../utils/constants/queryParamsKeys'
import { sortBy, sortType } from '../../utils/constants/sortTypes'
import { isPrivate } from '../../utils/helpers/isPrivate'
import { OneClientType } from '../../utils/models/ClientsType'
import { rootPaths } from '../../utils/routes/rootPaths'
import ClientModal from '../clientModal/ClientModal'
import ClientsExcelModal from './ClientsExcelModal'
import PlusIcon from '../../assets/svg/PlusIcon'

import '../../styles/CustomTable.scss'
import DownloadIcon from '../../assets/svg/DownloadIcon'

const ClientsLeft: React.FC = () => {
  const { Column } = Table
  const [searchTxt, setSearchTxt] = useState('')
  const [page, setPage] = useState(1)
  const [sort, setSort] = useState<{
    by: string
    type: string
  } | null>(null)
  const { data, isLoading } = useClients(page, searchTxt, sort)
  const timerRef = React.useRef<any>()
  const navigate = useNavigate()
  const [clientModalVisible, setClientModalVisible] = useState(false)
  const [clientModalData, setClientModalData] = useState<OneClientType | null>(null)
  const { sm } = Grid.useBreakpoint()
  const [visibleExcelModal, setVisibleExcelModal] = useState(false)

  // balance color
  const balanceColor = (balance: number) => {
    if (balance > 0) {
      return colors.green
    } else if (balance < 0) {
      return colors.red
    } else {
      return ''
    }
  }

  // change page and page size
  const handlePage = (page: number) => {
    setPage(page)
  }

  // handle search text
  const handleSearchTxt = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e?.target?.value

    clearTimeout(timerRef.current)
    timerRef.current = setTimeout(() => {
      setSearchTxt(value.length > 2 ? value : '')
    }, 500)
  }

  // open client modal
  const openClientModal = () => {
    setClientModalVisible(true)
  }

  // client description
  const description = (description: string) => {
    if (description.length > 45) {
      return description.substring(0, 45) + '...'
    } else {
      return description
    }
  }

  // stopPropagation
  const stopPropagation = (e: React.MouseEvent<HTMLElement> | undefined) => {
    e?.stopPropagation()
  }

  /**
   * update client */
  const updateClient = (
    e: React.MouseEvent<HTMLSpanElement> | undefined,
    clientData: OneClientType
  ) => {
    stopPropagation(e)
    setClientModalData(clientData)
    setClientModalVisible(true)
  }

  // to detailed client page
  const toDetailedClient = (clientId: number) => {
    if (isPrivate()) {
      navigate(`${rootPaths.clients}/${clientId}?${queryParamsKeys.PRIVATE}=${true}`)
    } else {
      navigate(`${rootPaths.clients}/${clientId}`)
    }
  }

  // change sort
  const changeSort = (sortBy: string) => {
    let type

    if (sort?.type === sortType.ASC) {
      type = sortType.DESC
    } else {
      type = sortType.ASC
    }

    setSort({
      type,
      by: sortBy,
    })
  }

  // title classname
  const titleClassName = (sortBy: string) => {
    if (sort?.by === sortBy) {
      return 'active'
    }
    return ''
  }

  // parse formatted phone
  const parseFormattedPhone = (phone: string) => {
    return phone.replace(/[()-]/g, '')
  }

  // open excel modal
  const openExcelModal = () => {
    setVisibleExcelModal(true)
  }

  return (
    <div className="clients_left">
      <div className="clients_left_header">
        <Input
          placeholder="Mijoz nomi yoki raqami orqali qidirish"
          prefix={<SearchIcon />}
          onChange={handleSearchTxt}
        />
        <div className="df">
          <Button className="excel" type="primary" onClick={openExcelModal}>
            <DownloadIcon /> {sm && 'Excel'}
          </Button>
          <Button className="create" type="primary" onClick={openClientModal}>
            <PlusIcon /> {sm && 'Yangi yaratish'}
          </Button>
        </div>
      </div>
      <div className="custom_table">
        <Table
          dataSource={data?.clients}
          loading={isLoading}
          rowKey={(record: OneClientType) => record.id}
          pagination={false}
          onRow={(record: OneClientType) => {
            return {
              onClick: () => toDetailedClient(record.id),
            }
          }}
        >
          <Column
            title={
              <span
                className={`${titleClassName(sortBy.NAME)} cur_p`}
                onClick={() => changeSort(sortBy.NAME)}
              >
                Mijozlar
              </span>
            }
            className="name"
            dataIndex="name"
          />
          {sm && (
            <>
              <Column
                title="Telefon raqami"
                className="phone"
                render={(record: OneClientType) => (
                  <a
                    className="color_initial"
                    href={`${linkTypes.TEL}:${parseFormattedPhone(record?.phone)}`}
                    onClick={(e) => stopPropagation(e)}
                  >
                    {record.phone}
                  </a>
                )}
              />
              <Column
                title="Qo'shimcha raqami"
                className="phone"
                render={(record: OneClientType) =>
                  record.phone_1 ? (
                    <a
                      className="color_initial"
                      href={`${linkTypes.TEL}:${parseFormattedPhone(
                        record?.phone_1
                      )}`}
                      onClick={(e) => stopPropagation(e)}
                    >
                      {record.phone}
                    </a>
                  ) : null
                }
              />
              <Column
                title="Izoh"
                className="description"
                render={(record: OneClientType) => (
                  <Tooltip title={record.description}>
                    {description(record.description || '')}
                  </Tooltip>
                )}
              />
            </>
          )}
          <Column
            title={
              <span
                className={`${titleClassName(sortBy.BALANCE)} cur_p`}
                onClick={() => changeSort(sortBy.BALANCE)}
              >
                Balans
              </span>
            }
            align="right"
            render={(record: OneClientType) => (
              <p className="balance">
                <span className={balanceColor(record.balance)}>
                  {Math.abs(record?.balance)?.toLocaleString(locales.RU)}
                </span>
              </p>
            )}
          />
          {sm && (
            <Column
              title="To'lov turi"
              align="center"
              render={(record: OneClientType) => record?.currency?.toUpperCase()}
            />
          )}
          <Column
            render={(record: OneClientType) => (
              <span className="p4" onClick={(e) => updateClient(e, record)}>
                <EditIcon />
              </span>
            )}
            align="center"
          />
        </Table>
        <div className="pagination">
          <Pagination
            current={data?.current_page}
            total={data?.count}
            onChange={handlePage}
            itemRender={(
              _,
              type: 'page' | 'prev' | 'next' | 'jump-prev' | 'jump-next',
              element: React.ReactNode
            ) => {
              if (type === 'prev') {
                return (
                  <div className="prev_btn">
                    <PrevIcon />
                    <span>Oldingi</span>
                  </div>
                )
              }
              if (type === 'next') {
                return (
                  <div className="next_btn">
                    <span>Keyingi</span>
                    <NextIcon />
                  </div>
                )
              }
              return element
            }}
          />
        </div>
      </div>
      <ClientModal
        visible={clientModalVisible}
        setVisible={setClientModalVisible}
        clientData={clientModalData}
        setClientData={setClientModalData}
      />
      <ClientsExcelModal
        visible={visibleExcelModal}
        setVisible={setVisibleExcelModal}
      />
    </div>
  )
}

export default ClientsLeft
