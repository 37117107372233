import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Header from '../components/header/Header'
import { useAppSelector } from '../hooks/redux'
import { useCheckNewVersion } from '../queries/queries'
import Redirector from '../routing/Redirector'

type props = {
  children: React.ReactNode
}

const MainLayout: React.FC<props> = ({ children }) => {
  const { data: projectNewVersion } = useCheckNewVersion()
  const projectVersion = useAppSelector((state) => state.userReducer.projectVersion)
  const location = useLocation()

  console.log(projectVersion, projectNewVersion)

  useEffect(() => {
    if (projectVersion && projectNewVersion) {
      if (projectNewVersion !== projectVersion) {
        window.location.reload()
      }
    }
  }, [location, projectVersion, projectNewVersion])

  return (
    <>
      <Header />
      {children}
      <Redirector />
    </>
  )
}

export default MainLayout
