import { Button, Form, Input, Modal, Select } from 'antd'
import React, { ChangeEvent, useEffect, useState } from 'react'
import ReactInputMask from 'react-input-mask'

import CloseIcon from '../../assets/svg/CloseIcon'
import { useCreateClient, useUpdateClient } from '../../queries/mutations'
import { OneClientType } from '../../utils/models/ClientsType'
import { currencies } from '../../utils/constants/currencies'

import '../../styles/CustomModal.scss'
import './ClientModal.scss'
import { useUsdAmount } from '../../queries/queries'
import { locales } from '../../utils/constants/locales'
import { removeNaN } from '../../utils/helpers/removeNaN'
import { mathRound } from '../../utils/helpers/mathRound'
import { parseLocaledString } from '../../utils/helpers/parseLocaledString'

type props = {
  visible: boolean
  setVisible: (visible: boolean) => void
  clientData: OneClientType | null
  setClientData: (clientData: null) => void
}

type formFields = {
  name: string
  phone: string
  phone_1: string
  description: string
  currency: string
  client_balance: string
  currency_usd: string
}

const ClientModal: React.FC<props> = ({
  visible,
  setVisible,
  clientData,
  setClientData,
}) => {
  const { Item } = Form
  const [formInstance] = Form.useForm()
  const createClientMutation = useCreateClient(setVisible)
  const updateClientMutation = useUpdateClient(setVisible)
  const { Option } = Select
  const { data: usdAmount } = useUsdAmount()
  const [changeCurrency, setChangeCurrency] = useState(false)

  // initial form fields
  useEffect(() => {
    if (clientData) {
      formInstance.setFieldsValue({
        name: clientData.name,
        phone: clientData.phone,
        phone_1: clientData.phone_1,
        description: clientData.description,
        currency: clientData.currency,
      })
    }
  }, [formInstance, clientData])

  // close modal
  const onCancel = () => {
    setVisible(false)
  }

  // on finish
  const onFinish = (fields: formFields) => {
    if (!createClientMutation.isLoading && !updateClientMutation.isLoading) {
      if (clientData) {
        updateClientMutation.mutate({
          ...fields,
          id: clientData?.id,
          currency_usd: +parseLocaledString(fields.currency_usd),
          client_balance: +parseLocaledString(fields.client_balance),
        })
      } else {
        createClientMutation.mutate(fields)
      }
    }
  }

  // after close modal
  const afterClose = () => {
    formInstance.resetFields()
    setClientData(null)
    setChangeCurrency(false)
  }

  // on submit
  const onSubmit = () => {
    formInstance.submit()
  }

  // title
  const title = () => {
    if (clientData) {
      return 'Mijoz tahrirlash'
    }
    return '+ Yangi mijoz'
  }

  // change currency input
  const changeCurrencyInput = (
    e: ChangeEvent<HTMLInputElement> | undefined,
    formItemName: string
  ) => {
    let newValue = ''

    newValue = removeNaN(e?.target.value || '')

    const arr = newValue.split('.')

    if (arr.length === 1) {
      formInstance.setFieldsValue({
        [formItemName]: newValue ? (+newValue).toLocaleString(locales.RU) : '',
      })
    } else if (arr.length === 2) {
      formInstance.setFieldsValue({
        [formItemName]: newValue
          ? (+arr[0]).toLocaleString(locales.RU) + '.' + arr[1]?.slice(0, 2)
          : '',
      })
    } else {
      formInstance.setFieldsValue({
        [formItemName]: newValue
          ? (+arr[0]).toLocaleString(locales.RU) + '.' + arr[1]
          : '',
      })
    }
  }

  // handle currency amount
  const handleCurrencyAmount = (e: ChangeEvent<HTMLInputElement>) => {
    const currencyAmount = +removeNaN(e?.target?.value)
    const currency = formInstance.getFieldValue('currency')
    let newClientBalance = 0

    // check client currency
    if (currency === currencies.USD) {
      newClientBalance = mathRound(clientData?.balance! / currencyAmount)
    } else {
      newClientBalance = mathRound(clientData?.balance! * currencyAmount)
    }

    formInstance.setFieldsValue({
      client_balance: newClientBalance?.toLocaleString(locales.RU),
    })
  }

  // on change currency
  const onChangeCurrency = (currency: string) => {
    if (clientData?.currency === currency) {
      setChangeCurrency(false)
    } else {
      setChangeCurrency(true)
      let newClientBalance = 0

      if (currency === currencies.USD) {
        newClientBalance = clientData?.balance! / (usdAmount || 1)
      } else {
        newClientBalance = (usdAmount || 1) * clientData?.balance!
      }

      formInstance.setFieldsValue({
        client_balance: mathRound(newClientBalance)?.toLocaleString(locales.RU),
      })
    }
  }

  // client current balance
  const clientCurrentBalance = () => {
    // if (clientData?.currency === currencies.UZS) {
    //   return mathRound(clientData?.balance * (usdAmount || 1))
    // } else
    return clientData?.balance
  }

  return (
    <Modal
      visible={visible}
      closable={false}
      onCancel={onCancel}
      centered
      className="custom_modal client_modal"
      footer={null}
      afterClose={afterClose}
    >
      <div className="custom_modal_header">
        <h1>{title()}</h1>
        <div className="close_icon" onClick={onCancel}>
          <CloseIcon />
        </div>
      </div>
      <div className="custom_modal_body">
        <Form layout="vertical" form={formInstance} onFinish={onFinish}>
          <Item
            label="Mijoz"
            name="name"
            rules={[{ required: true, message: 'Mijoz nomini kiriting!' }]}
          >
            <Input />
          </Item>
          <Item
            label="Telefon raqam"
            name="phone"
            rules={[
              { required: true, message: 'Mijoz telefon raqamini kiriting!' },
              () => ({
                validator(_, value) {
                  if (value && value.replace(/[+()_-]/g, '').length !== 12) {
                    return Promise.reject()
                  }
                  return Promise.resolve()
                },
              }),
            ]}
          >
            <ReactInputMask
              // @ts-ignore*
              maskChar=""
              className="ant-input"
              mask="+\9\98(99)-999-99-99"
            />
          </Item>
          <Item
            label="Qo'shimcha telefon raqam (ixtiyoriy)"
            name="phone_1"
            rules={[
              () => ({
                validator(_, value) {
                  if (value && value.replace(/[+()_-]/g, '').length !== 12) {
                    return Promise.reject()
                  }
                  return Promise.resolve()
                },
              }),
            ]}
          >
            <ReactInputMask
              // @ts-ignore*
              maskChar=""
              className="ant-input"
              mask="+\9\98(99)-999-99-99"
            />
          </Item>

          <div className="current_balance">
            <Item
              label="Mijoz to'lov turi"
              name="currency"
              rules={[{ required: true, message: "Mijoz to'lov turini kiriting!" }]}
              initialValue={currencies.USD}
              className={`${changeCurrency && clientData ? 'mr-16' : ''}`}
            >
              <Select onChange={onChangeCurrency}>
                <Option value={currencies.UZS}>UZS</Option>
                <Option value={currencies.USD}>USD</Option>
              </Select>
            </Item>
            {clientData && changeCurrency ? (
              <Item
                label={`Mijoz joriy balansi (${clientData?.currency?.toUpperCase()})`}
              >
                <Input
                  disabled
                  value={clientCurrentBalance()?.toLocaleString(locales.RU)}
                />
              </Item>
            ) : null}
          </div>
          {clientData && changeCurrency ? (
            <div className="currency_cont">
              <Item
                name="currency_usd"
                label="Dollar kursi"
                rules={[
                  {
                    required: true,
                    message: 'Kursni kiriting!',
                  },
                ]}
                initialValue={usdAmount?.toLocaleString(locales.RU)}
              >
                <Input
                  onChange={async (e) => {
                    await changeCurrencyInput(e, 'currency_usd')
                    handleCurrencyAmount(e)
                  }}
                />
              </Item>
              <Item name="client_balance" label="Mijoz balansi">
                <Input disabled />
              </Item>
            </div>
          ) : null}
          <Item label="Izoh" name="description">
            <Input.TextArea rows={3} />
          </Item>

          {/* for working submit when press enter key */}
          <Button htmlType="submit" className="dn" />
        </Form>
      </div>
      <div className="custom_modal_footer">
        <Button className="cancel_btn" onClick={onCancel}>
          Bekor qilish
        </Button>
        <Button
          type="primary"
          className="submit_btn"
          htmlType="submit"
          onClick={onSubmit}
          loading={createClientMutation.isLoading || updateClientMutation.isLoading}
        >
          Saqlash
        </Button>
      </div>
    </Modal>
  )
}

export default ClientModal
