import React from 'react'

const DeleteIcon: React.FC = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.0466 3.48659C12.9733 3.37992 11.8999 3.29992 10.8199 3.23992V3.23325L10.6733 2.36659C10.5733 1.75325 10.4266 0.833252 8.86661 0.833252H7.11994C5.56661 0.833252 5.41994 1.71325 5.31328 2.35992L5.17328 3.21325C4.55328 3.25325 3.93328 3.29325 3.31328 3.35325L1.95328 3.48659C1.67328 3.51325 1.47328 3.75992 1.49994 4.03325C1.52661 4.30659 1.76661 4.50659 2.04661 4.47992L3.40661 4.34659C6.89994 3.99992 10.4199 4.13325 13.9533 4.48659C13.9733 4.48659 13.9866 4.48659 14.0066 4.48659C14.2599 4.48659 14.4799 4.29325 14.5066 4.03325C14.5266 3.75992 14.3266 3.51325 14.0466 3.48659Z"
        fill="#5A6474"
      />
      <path
        d="M12.82 5.42675C12.66 5.26008 12.44 5.16675 12.2134 5.16675H3.7867C3.56004 5.16675 3.33337 5.26008 3.18004 5.42675C3.0267 5.59341 2.94004 5.82008 2.95337 6.05341L3.3667 12.8934C3.44004 13.9067 3.53337 15.1734 5.86004 15.1734H10.14C12.4667 15.1734 12.56 13.9134 12.6334 12.8934L13.0467 6.06008C13.06 5.82008 12.9734 5.59341 12.82 5.42675ZM9.1067 11.8334H6.8867C6.61337 11.8334 6.3867 11.6067 6.3867 11.3334C6.3867 11.0601 6.61337 10.8334 6.8867 10.8334H9.1067C9.38004 10.8334 9.6067 11.0601 9.6067 11.3334C9.6067 11.6067 9.38004 11.8334 9.1067 11.8334ZM9.6667 9.16675H6.33337C6.06004 9.16675 5.83337 8.94008 5.83337 8.66675C5.83337 8.39341 6.06004 8.16675 6.33337 8.16675H9.6667C9.94004 8.16675 10.1667 8.39341 10.1667 8.66675C10.1667 8.94008 9.94004 9.16675 9.6667 9.16675Z"
        fill="#5A6474"
      />
    </svg>
  )
}

export default DeleteIcon
