import { Button, Grid, Pagination, Popconfirm, Table, Tooltip } from 'antd'
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import IncomeIcon from '../../assets/svg/IncomeIcon'
import NextIcon from '../../assets/svg/NextIcon'
import OutcomeIcon from '../../assets/svg/OutcomeIcon'
import { paymentTypes } from '../../utils/constants/paymentTypes'
import { OnePaymentType } from '../../utils/models/PaymentType'
import PaymentModal from '../paymentModal/PaymentModal'
import PrevIcon from '../../assets/svg/PrevIcon'
import { usePayments } from '../../queries/queries'
import EditIcon from '../../assets/svg/EditIcon'
import DeleteIcon from '../../assets/svg/DeleteIcon'
import { useDeletePayment, usePaymentProducts } from '../../queries/mutations'
import { paymentStatus } from '../../utils/constants/paymentStatus'
import { sortBy, sortType } from '../../utils/constants/sortTypes'
import ProductModal from '../productModal/ProductModal'
import { locales } from '../../utils/constants/locales'
import ViewIcon from '../../assets/svg/ViewIcon'

import '../../styles/CustomTable.scss'
import DownloadIcon from '../../assets/svg/DownloadIcon'
import PaymentExcelModal from './PaymentExcelModal'

type props = {
  selectedPaymentId: number
  setSelectedPaymentId: (id: number) => void
}

const DetailedClientLeft: React.FC<props> = ({
  selectedPaymentId,
  setSelectedPaymentId,
}) => {
  const params = useParams()
  const { Column } = Table
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(10)
  const [sort, setSort] = useState<{
    by: string
    type: string
  } | null>(null)
  const { data, isLoading } = usePayments(params.id || '', page, size, sort)
  const [modalVisible, setModalVisible] = useState(false)
  const [paymentType, setPaymentType] = useState<string | null>(null)
  const [paymentModalData, setPaymentModalData] = useState<OnePaymentType | null>(
    null
  )
  const deletePaymentMutation = useDeletePayment()
  const navigate = useNavigate()
  const getProductsMutation = usePaymentProducts()
  const { sm } = Grid.useBreakpoint()
  const [viewMode, setViewMode] = useState(false)
  const [visibleExcelModal, setVisibleExcelModal] = useState(false)

  // payment information
  const information = (info: string) => {
    if (info.length > 45) {
      return info.substring(0, 45) + '...'
    } else {
      return info
    }
  }

  // change page and page size
  const handlePage = (page: number, pageSize: number) => {
    setPage(page)
    setSize(pageSize)
    setSelectedPaymentId(0)
  }

  // income
  const payment = (
    amount: number,
    type: string,
    dataIndex: string,
    status: string,
    currency: string
  ) => {
    if (type === dataIndex) {
      // payment icon
      const paymentIcon = () => {
        switch (type) {
          case paymentTypes.INCOME:
            return <IncomeIcon />
          case paymentTypes.OUTCOME:
            return <OutcomeIcon />
          default:
            return null
        }
      }

      return (
        <div className={`${deletedTextClassName(status)} payment`}>
          <p className="cont">
            {paymentIcon()}
            <span className="amount">
              {Math.abs(amount)?.toLocaleString(locales.RU)}
            </span>
            &nbsp;
            <span className="dollar_icon">{currency?.toUpperCase()}</span>
          </p>
        </div>
      )
    }
    return null
  }

  // open modal
  const openModal = (paymentType: string) => {
    setModalVisible(true)
    setPaymentType(paymentType)
    setSelectedPaymentId(0)
  }

  // update payment
  const updatePayment = (
    e: React.MouseEvent<HTMLSpanElement> | undefined,
    paymentData: OnePaymentType
  ) => {
    e?.stopPropagation()
    getProductsMutation.mutate(paymentData.id)
    setModalVisible(true)
    setPaymentType(null)
    setPaymentModalData(paymentData)
    setSelectedPaymentId(0)
  }

  // view payment
  const viewPayment = (
    e: React.MouseEvent<HTMLSpanElement> | undefined,
    paymentData: OnePaymentType
  ) => {
    e?.stopPropagation()
    getProductsMutation.mutate(paymentData.id)
    setModalVisible(true)
    setPaymentType(null)
    setViewMode(true)
    setPaymentModalData(paymentData)
    setSelectedPaymentId(0)
  }

  // delete payment
  const deletePayment = (paymentId: number) => {
    deletePaymentMutation.mutate(paymentId)
  }

  // go back to previous page
  const goBack = () => {
    navigate(-1)
  }

  // deleted text className
  const deletedTextClassName = (status: string) => {
    if (status === paymentStatus.PASSIVE) {
      return 'deleted_text'
    }
    return ''
  }

  // stop propagation
  const stopPropagation = (e: React.MouseEvent<HTMLSpanElement> | undefined) => {
    e?.stopPropagation()
  }

  // change sort
  const changeSort = (sortBy: string) => {
    let type

    if (sort?.type === sortType.ASC) {
      type = sortType.DESC
    } else {
      type = sortType.ASC
    }

    setSort({
      type,
      by: sortBy,
    })
  }

  // title classname
  const titleClassName = (sortBy: string) => {
    if (sort?.by === sortBy) {
      return 'active'
    }
    return ''
  }

  // open excel modal
  const openExcelModal = () => {
    setVisibleExcelModal(true)
  }

  return (
    <div className="detailed_client_left">
      <div className="detailed_client_left_header">
        <div className="left">
          <div className="prev_icon" onClick={goBack}>
            <PrevIcon />
          </div>
          <h1>{data?.client_name}</h1>
        </div>
        <div className="right">
          <Button className="excel" type="primary" onClick={openExcelModal}>
            <DownloadIcon /> {sm && 'Excel'}
          </Button>
          <Button className="income" onClick={() => openModal(paymentTypes.INCOME)}>
            {sm ? '+ Mijoz balansiga kirim' : '+ Kirim'}
          </Button>
          <Button
            className="outcome"
            onClick={() => openModal(paymentTypes.OUTCOME)}
          >
            {sm ? '- Mijoz balansidan chiqim' : '- Chiqim'}
          </Button>
        </div>
      </div>
      <div className="custom_table">
        <Table
          dataSource={data?.payments}
          loading={isLoading}
          rowKey={(record: OnePaymentType) => record.id}
          pagination={false}
          onRow={(record: OnePaymentType) => {
            return {
              onClick: () => setSelectedPaymentId(record.id),
            }
          }}
          rowClassName={(record: OnePaymentType) =>
            record.id === selectedPaymentId ? 'selected_row' : ''
          }
        >
          {sm && (
            <>
              <Column
                title={
                  <span
                    className={`${titleClassName(sortBy.DATE)} cur_p`}
                    onClick={() => changeSort(sortBy.DATE)}
                  >
                    Sana
                  </span>
                }
                className="date"
                render={(record: OnePaymentType) => (
                  <span className={deletedTextClassName(record.status)}>
                    {record.date}
                  </span>
                )}
              />
              <Column
                title="Izoh"
                className="info"
                render={(record: OnePaymentType) => (
                  <Tooltip title={record.information}>
                    <p className={`${deletedTextClassName(record.status)} m0`}>
                      {information(record.information || '')}
                    </p>
                  </Tooltip>
                )}
              />
            </>
          )}
          <Column
            title={
              <span
                className={`${titleClassName(sortBy.INCOME)} cur_p`}
                onClick={() => changeSort(sortBy.INCOME)}
              >
                Kirim
              </span>
            }
            className="payment"
            render={(record: OnePaymentType) =>
              payment(
                record.payment_table,
                record.type,
                paymentTypes.INCOME,
                record.status,
                record.currency
              )
            }
            align="right"
          />
          <Column
            title={
              <span
                className={`${titleClassName(sortBy.OUTCOME)} cur_p`}
                onClick={() => changeSort(sortBy.OUTCOME)}
              >
                Chiqim
              </span>
            }
            className="payment"
            render={(record: OnePaymentType) =>
              payment(
                record.payment_table,
                record.type,
                paymentTypes.OUTCOME,
                record.status,
                record.currency
              )
            }
            align="right"
          />
          {sm && (
            <Column
              title="Yaratdi"
              className="creator"
              render={(record: OnePaymentType) => (
                <span className={deletedTextClassName(record.status)}>
                  {record.name}
                </span>
              )}
            />
          )}
          <Column
            render={(record: OnePaymentType) =>
              record.status === paymentStatus.ACTIVE ? (
                <div className="actions">
                  <span
                    className="p4 mr-16 view"
                    onClick={(e) => viewPayment(e, record)}
                  >
                    <ViewIcon />
                  </span>
                  <span
                    className="p4 mr-16"
                    onClick={(e) => updatePayment(e, record)}
                  >
                    <EditIcon />
                  </span>
                  <Popconfirm
                    title="To'lovni o'chirmoqchimisiz?"
                    onConfirm={(e) => {
                      deletePayment(record.id)
                      stopPropagation(e)
                    }}
                    onCancel={(e) => stopPropagation(e)}
                    okText="Ha"
                    cancelText="Yo'q"
                    placement="left"
                    okButtonProps={{
                      loading: deletePaymentMutation.isLoading,
                    }}
                  >
                    <span className="p4" onClick={(e) => stopPropagation(e)}>
                      <DeleteIcon />
                    </span>
                  </Popconfirm>
                </div>
              ) : (
                <p className="deleted_status">{sm ? "O'chirilgan!" : "O'chgan"}</p>
              )
            }
            align="center"
          />
        </Table>
        <div className="pagination">
          <Pagination
            current={data?.current_page}
            total={data?.count}
            onChange={handlePage}
            itemRender={(
              _,
              type: 'page' | 'prev' | 'next' | 'jump-prev' | 'jump-next',
              element: React.ReactNode
            ) => {
              if (type === 'prev') {
                return (
                  <div className="prev_btn">
                    <PrevIcon />
                    <span>Oldingi</span>
                  </div>
                )
              }
              if (type === 'next') {
                return (
                  <div className="next_btn">
                    <span>Keyingi</span>
                    <NextIcon />
                  </div>
                )
              }
              return element
            }}
          />
        </div>
      </div>
      <PaymentModal
        visible={modalVisible}
        setVisible={setModalVisible}
        paymentType={paymentType}
        paymentData={paymentModalData}
        setPaymentData={setPaymentModalData}
        viewMode={viewMode}
        setViewMode={setViewMode}
        clientCurrency={data?.client_currency}
      />
      <ProductModal viewMode={viewMode} clientCurrency={data?.client_currency} />
      <PaymentExcelModal
        visible={visibleExcelModal}
        setVisible={setVisibleExcelModal}
        clientName={data?.client_name}
        clientId={params.id}
      />
    </div>
  )
}

export default DetailedClientLeft
