import { addPrivateEndpoint } from './addPrivateEndpoint'

type KeyString = { [key: string]: string }

export function addControllerPrefix(endpoints: KeyString, prefix: string): any {
  for (const key in endpoints) {
    endpoints[key] = prefix + endpoints[key] + addPrivateEndpoint()
  }
  return endpoints
}
