import { Button, Form, Modal, Select } from 'antd'
import React from 'react'
import CloseIcon from '../../assets/svg/CloseIcon'
import { usePaymentsExcel } from '../../queries/mutations'
import { paymentTypes } from '../../utils/constants/paymentTypes'

type props = {
  visible: boolean
  setVisible: (visible: boolean) => void
  clientName: string | undefined
  clientId: string | undefined
}

type formFields = {
  paymentType: string
}

const { Item } = Form
const { Option } = Select

const PaymentExcelModal: React.FC<props> = ({
  setVisible,
  visible,
  clientName,
  clientId,
}) => {
  const downloadExcel = usePaymentsExcel(setVisible, clientName, clientId)
  const [formInstance] = Form.useForm()

  // handle cancel
  const handleCancel = () => {
    setVisible(false)
  }

  // handle submit
  const handleSubmit = () => {
    formInstance.submit()
  }

  // after close modal
  const afterClose = () => {
    formInstance.resetFields()
  }

  // on finish
  const onFinish = (fields: formFields) => {
    downloadExcel.mutate(fields.paymentType)
  }

  return (
    <Modal
      visible={visible}
      closable={false}
      onCancel={handleCancel}
      centered
      className="custom_modal clients_excel_modal"
      footer={null}
      afterClose={afterClose}
    >
      <div className="custom_modal_header">
        <h1>Excel</h1>
        <div className="close_icon" onClick={handleCancel}>
          <CloseIcon />
        </div>
      </div>
      <div className="custom_modal_body">
        <Form layout="vertical" form={formInstance} onFinish={onFinish}>
          <Item name="paymentType" label="To'lov turi" initialValue={null}>
            <Select>
              <Option value={null}>Barchasi</Option>
              <Option value={paymentTypes.INCOME}>Kirim</Option>
              <Option value={paymentTypes.OUTCOME}>Chiqim</Option>
            </Select>
          </Item>
        </Form>
      </div>
      <div className="custom_modal_footer">
        <Button className="cancel_btn" onClick={handleCancel}>
          Bekor qilish
        </Button>
        <Button
          type="primary"
          className="submit_btn"
          htmlType="submit"
          onClick={handleSubmit}
          loading={downloadExcel.isLoading}
        >
          Yuklash
        </Button>
      </div>
    </Modal>
  )
}

export default PaymentExcelModal
