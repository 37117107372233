import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { sliceNames } from '../../utils/constants/sliceNames'
import { PaymentProductType } from '../../utils/models/PaymentProductType'

const initialState: {
  products: PaymentProductType[] | []
  visibleProductModal: boolean
} = {
  products: [],
  visibleProductModal: false,
}

export const productReducer = createSlice({
  name: sliceNames.PRODUCTS,
  initialState,
  reducers: {
    setVisibleProductModal(state, action: PayloadAction<boolean>) {
      state.visibleProductModal = action.payload
    },
    setProducts(state, action: PayloadAction<PaymentProductType[] | []>) {
      state.products = action.payload
    },
    addProduct(state, action: PayloadAction<PaymentProductType>) {
      state.products = [action.payload, ...state.products]
    },
    removeProduct(state, action: PayloadAction<number>) {
      state.products = state.products.filter((_, index) => index !== action.payload)
    },
    updateProduct(
      state,
      action: PayloadAction<{ product: PaymentProductType; index: number }>
    ) {
      let updatedProducts = state.products

      updatedProducts?.forEach((_, index) => {
        if (index === action.payload.index) {
          updatedProducts[index] = action.payload.product
        }
      })

      state.products = updatedProducts
    },
  },
})

export default productReducer.reducer
