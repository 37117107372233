import { Button, Form, Input, Modal, Select } from 'antd'
import dayjs from 'dayjs'
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import ReactInputMask from 'react-input-mask'
import { useParams } from 'react-router-dom'

import CloseIcon from '../../assets/svg/CloseIcon'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { useCreatePayment, useUpdatePayment } from '../../queries/mutations'
import { useUsdAmount } from '../../queries/queries'
import { productReducer } from '../../redux/reducers/productReducer'
import { currencies } from '../../utils/constants/currencies'
import { dayjsTypes } from '../../utils/constants/dayjsTypes'
import { locales } from '../../utils/constants/locales'
import { paymentTypes } from '../../utils/constants/paymentTypes'
import { mathRound } from '../../utils/helpers/mathRound'
import { parseLocaledString } from '../../utils/helpers/parseLocaledString'
import { removeNaN } from '../../utils/helpers/removeNaN'
import { validateDateString } from '../../utils/helpers/validatorDateString'
import { OnePaymentType, PaymentPayloadType } from '../../utils/models/PaymentType'

import './PaymentModal.scss'

type props = {
  visible: boolean
  setVisible: (visible: boolean) => void
  paymentType: string | null
  paymentData: OnePaymentType | null
  setPaymentData: (data: null) => void
  viewMode: boolean
  clientCurrency: string | undefined
  setViewMode: (viewMode: boolean) => void
}

type formFields = {
  currency: string
  date: string
  information: string | undefined
  payment: string
  payment_currency: string
  currency_usd: string
}

const PaymentModal: React.FC<props> = ({
  setVisible,
  visible,
  paymentType,
  paymentData,
  setPaymentData,
  viewMode,
  clientCurrency,
  setViewMode,
}) => {
  const [formInstance] = Form.useForm()
  const params = useParams()
  const { Item } = Form
  const { Option } = Select
  const [currency, setCurrency] = useState(clientCurrency)
  const { data: usdAmount } = useUsdAmount()
  const [currencyAmount, setCurrencyAmount] = useState(0)
  const createPaymentMutation = useCreatePayment(setVisible)
  const updatePaymentMutation = useUpdatePayment(setVisible)
  const dispatch = useAppDispatch()
  const { setVisibleProductModal, setProducts } = productReducer.actions
  const { products } = useAppSelector((state) => state.productReducer)
  const { visibleProductModal } = useAppSelector((state) => state.productReducer)

  // is not empty products
  const isNotEmptyProducts = products.length > 0

  // initial currency
  useEffect(() => {
    if (!paymentData) {
      setCurrency(clientCurrency)
    }
  }, [paymentData, clientCurrency])

  // initial form fields
  useEffect(() => {
    if (paymentData) {
      formInstance.setFieldsValue({
        currency: paymentData.currency,
        date: paymentData.date,
        information: paymentData.information,
        payment: paymentData.payment?.toLocaleString(locales.RU),
        payment_currency: paymentData.payment_currency?.toLocaleString(locales.RU),
        currency_usd: paymentData.currency_usd?.toLocaleString(locales.RU),
      })
      setCurrency(paymentData.currency)
    } else {
      if (usdAmount) {
        setCurrencyAmount(usdAmount)
        formInstance.setFieldsValue({
          currency_usd: usdAmount.toLocaleString(locales.RU),
        })
      }
      formInstance.setFieldsValue({
        date: dayjs().format(dayjsTypes.dateType),
      })
    }
  }, [usdAmount, formInstance, paymentData, visible])

  // total payment with products
  const totalPaymentWithProducts = useCallback(() => {
    let totalPayment = 0

    products.forEach((product) => {
      totalPayment += product.overall_price
    })

    return mathRound(totalPayment)
  }, [products])

  // set currency USD and total price
  useEffect(() => {
    if (isNotEmptyProducts) {
      formInstance.setFieldsValue({
        // payment: totalPaymentWithProducts().toLocaleString(locales.RU),
      })
    }
  }, [formInstance, totalPaymentWithProducts, isNotEmptyProducts, paymentData])

  useEffect(() => {
    if (visibleProductModal && isNotEmptyProducts) {
      setCurrency(clientCurrency)
      formInstance.setFieldsValue({
        currency: clientCurrency,
        payment: totalPaymentWithProducts().toLocaleString(locales.RU),
      })
    }
  }, [
    clientCurrency,
    totalPaymentWithProducts,
    visibleProductModal,
    isNotEmptyProducts,
    formInstance,
  ])

  // modal title
  const modalTitle = () => {
    if (viewMode) {
      return "Ko'rish"
    }

    switch (paymentType) {
      case paymentTypes.INCOME:
        return 'Kirim'
      case paymentTypes.OUTCOME:
        return 'Chiqim'
      case null:
        return 'Tahrirlash'
      default:
        return ''
    }
  }

  // close modal
  const onCancel = () => {
    setVisible(false)
  }

  // on finish
  const onFinish = (fields: formFields) => {
    let payload: PaymentPayloadType = {
      ...fields,
      payment: parseLocaledString(fields?.payment),
      client_id: +params?.id!,
      payment_currency: parseLocaledString(fields?.payment_currency),
      currency_usd: parseLocaledString(fields?.currency_usd),
      type: paymentType,
    }

    if (isNotEmptyProducts) {
      payload = {
        ...payload,
        products: products,
      }
    }

    if (!createPaymentMutation.isLoading && !updatePaymentMutation.isLoading) {
      if (paymentData) {
        updatePaymentMutation.mutate({ ...payload, id: paymentData.id })
      } else {
        createPaymentMutation.mutate(payload)
      }
    }
  }

  // after close modal
  const afterClose = () => {
    formInstance.resetFields()
    setCurrency(clientCurrency)
    setPaymentData(null)
    dispatch(setProducts([]))
    setViewMode(false)
  }

  // on submit
  const onSubmit = () => {
    formInstance.submit()
  }

  // change currency input
  const changeCurrencyInput = (
    e: ChangeEvent<HTMLInputElement> | undefined,
    formItemName: string
  ) => {
    let newValue = ''

    newValue = removeNaN(e?.target.value || '')

    const arr = newValue.split('.')

    if (arr.length === 1) {
      formInstance.setFieldsValue({
        [formItemName]: newValue ? (+newValue).toLocaleString(locales.RU) : '',
      })
    } else if (arr.length === 2) {
      formInstance.setFieldsValue({
        [formItemName]: newValue
          ? (+arr[0]).toLocaleString(locales.RU) + '.' + arr[1]?.slice(0, 3)
          : '',
      })
    } else {
      formInstance.setFieldsValue({
        [formItemName]: newValue
          ? (+arr[0]).toLocaleString(locales.RU) + '.' + arr[1]
          : '',
      })
    }
  }

  // change currency
  const onChangeCurrency = (currency: string) => {
    const payment = +parseLocaledString(formInstance.getFieldValue('payment'))

    const payment_currency = +removeNaN(
      formInstance.getFieldValue('payment_currency')
    )

    // check client currency
    if (clientCurrency === currencies.USD) {
      // check payment currency
      if (currency === currencies.UZS) {
        formInstance.setFieldsValue({
          payment_currency: payment.toLocaleString(locales.RU),
          payment: mathRound(payment / currencyAmount || 0).toLocaleString(
            locales.RU
          ),
        })
      } else {
        formInstance.setFieldsValue({
          payment: payment_currency.toLocaleString(locales.RU),
        })
      }
    } else {
      // check payment currency
      if (currency === currencies.USD) {
        formInstance.setFieldsValue({
          payment_currency: payment.toLocaleString(locales.RU),
          payment: mathRound(payment * currencyAmount || 0).toLocaleString(
            locales.RU
          ),
        })
      } else {
        formInstance.setFieldsValue({
          payment: payment_currency.toLocaleString(locales.RU),
        })
      }
    }

    setCurrency(currency)
  }

  // handle currency amount
  const handleCurrencyAmount = (e: ChangeEvent<HTMLInputElement>) => {
    const paymentCurrency = +removeNaN(
      formInstance.getFieldValue('payment_currency')
    )
    const amount = +removeNaN(e?.target?.value)

    setCurrencyAmount(amount)

    // check client currency
    if (clientCurrency === currencies.USD) {
      formInstance.setFieldsValue({
        payment: mathRound(paymentCurrency / amount)?.toLocaleString(locales.RU),
      })
    } else {
      formInstance.setFieldsValue({
        payment: mathRound(paymentCurrency * amount)?.toLocaleString(locales.RU),
      })
    }
  }

  // handle payment uzs
  const handlePaymentCurrency = (e: ChangeEvent<HTMLInputElement>) => {
    // check client currency
    if (clientCurrency === currencies.USD) {
      formInstance.setFieldsValue({
        payment: mathRound(
          +removeNaN(e?.target?.value) / currencyAmount
        ).toLocaleString(locales.RU),
      })
    } else {
      formInstance.setFieldsValue({
        payment: mathRound(
          +removeNaN(e?.target?.value) * currencyAmount
        ).toLocaleString(locales.RU),
      })
    }
  }

  // open product modal
  const openProductModal = () => {
    dispatch(setVisibleProductModal(true))
  }

  // detailed payment fields
  const detailedPaymentFields = () => {
    if (clientCurrency === currencies.USD) {
      if (currency === currencies.UZS) return true
      return false
    } else {
      if (currency === currencies.USD) return true
      return false
    }
  }

  // payment label
  const paymentLabel = () => {
    if (clientCurrency === currencies.UZS) {
      if (currency === currencies.USD) return 'Summa (UZS)'
      return 'Summa'
    } else {
      if (currency === currencies.UZS) return 'Summa (USD)'
      return 'Summa'
    }
  }

  return (
    <Modal
      visible={visible}
      closable={false}
      onCancel={onCancel}
      centered
      className="custom_modal payment_modal"
      footer={null}
      afterClose={afterClose}
    >
      <div className="custom_modal_header">
        <h1>{modalTitle()}</h1>
        <Button onClick={openProductModal}>
          {!viewMode ? "Mahsulot qo'shish" : "Mahsulotlarni ko'rish"}
        </Button>
        <div className="close_icon" onClick={onCancel}>
          <CloseIcon />
        </div>
      </div>
      <div className="custom_modal_body payment_form">
        <Form
          layout="vertical"
          form={formInstance}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Item
            name="date"
            label="Sana"
            rules={[
              { required: true, message: 'Sanani kiriting!' },
              {
                validator: (_, value) => {
                  if (value) {
                    formInstance.setFieldsValue({
                      date: validateDateString(value, true),
                    })
                  }
                  return Promise.resolve()
                },
              },
            ]}
          >
            <ReactInputMask
              // @ts-ignore*
              maskChar=""
              className="ant-input"
              mask="99.99.9999"
            />
          </Item>
          <Item name="information" label="Izoh">
            <Input.TextArea rows={3} />
          </Item>
          {detailedPaymentFields() && (
            <div className="amount_cont_uzs">
              <Item
                name="payment_currency"
                label="Summa"
                rules={[
                  {
                    required: true,
                    message: 'Summani kiriting!',
                  },
                ]}
              >
                <Input
                  onChange={async (e) => {
                    await changeCurrencyInput(e, 'payment_currency')
                    handlePaymentCurrency(e)
                  }}
                  disabled={isNotEmptyProducts}
                />
              </Item>
              <Item
                name="currency"
                label="Valyuta"
                rules={[
                  {
                    required: true,
                    message: 'Valyutani tanlang!',
                  },
                ]}
                className="ml-16 mr-16"
              >
                <Select value={currency} onChange={onChangeCurrency}>
                  <Option value={currencies.UZS}>UZS</Option>
                  <Option value={currencies.USD}>USD</Option>
                </Select>
              </Item>
              <Item
                name="currency_usd"
                label="Dollar kursi"
                rules={[
                  {
                    required: true,
                    message: 'Kursni kiriting!',
                  },
                ]}
                className="currency_usd"
                initialValue={usdAmount?.toLocaleString(locales.RU)}
              >
                <Input
                  onChange={async (e) => {
                    await changeCurrencyInput(e, 'currency_usd')
                    handleCurrencyAmount(e)
                  }}
                />
              </Item>
            </div>
          )}
          <div className="amount_cont">
            <Item
              name="payment"
              label={paymentLabel()}
              rules={[
                {
                  required: true,
                  message: 'Summani kiriting!',
                },
              ]}
            >
              <Input
                onChange={(e) => changeCurrencyInput(e, 'payment')}
                disabled={currency !== clientCurrency || isNotEmptyProducts}
              />
            </Item>
            {!detailedPaymentFields() && (
              <Item
                name="currency"
                label="Valyuta"
                rules={[
                  {
                    required: true,
                    message: 'Valyutani tanlang!',
                  },
                ]}
                initialValue={clientCurrency}
                className="ml-16"
              >
                <Select value={currency} onChange={onChangeCurrency}>
                  <Option value={currencies.UZS}>UZS</Option>
                  <Option value={currencies.USD}>USD</Option>
                </Select>
              </Item>
            )}
          </div>

          {/* for working submit when press enter key */}
          <Button htmlType="submit" className="dn" />
        </Form>
      </div>
      {!viewMode && (
        <div className="custom_modal_footer">
          <Button className="cancel_btn" onClick={onCancel}>
            Bekor qilish
          </Button>
          <Button
            type="primary"
            className="submit_btn"
            htmlType="submit"
            onClick={onSubmit}
            loading={
              createPaymentMutation.isLoading || updatePaymentMutation.isLoading
            }
          >
            Saqlash
          </Button>
        </div>
      )}
    </Modal>
  )
}

export default PaymentModal
