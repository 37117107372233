export const queryParamsKeys = {
  PAGE: 'page',
  SIZE: 'size',
  SEARCH: 'search',
  CLIENT_ID: 'client_id',
  ID: 'id',
  PAYMENT_ID: 'payment_id',
  PRIVATE: 'private',
  SORT: 'sort',
  CURRENCY: 'currency',
  BALANCE: 'balance',
  TYPE: 'type',
}
