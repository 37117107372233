import { BrowserRouter } from 'react-router-dom'
import RootRouter from './routing/RootRouter'
import { ConfigProvider } from 'antd'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import './App.scss'
import { Provider } from 'react-redux'
import { setupStore } from './redux/store'

ConfigProvider.config({
  theme: {
    primaryColor: '#1EA5FC',
  },
})

const queryClient = new QueryClient()
const store = setupStore()

function App() {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <RootRouter />
        </BrowserRouter>
      </QueryClientProvider>
    </Provider>
  )
}

export default App
