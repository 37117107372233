import React, { useState } from 'react'

import DetailedClientLeft from '../../components/detailedClient/DetailedClientLeft'
import DetailedClientRight from '../../components/detailedClient/DetailedClientRight'
import MainLayout from '../../layouts/MainLayout'
import { eventKeys } from '../../utils/constants/eventKeys'
import { keyboardKeys } from '../../utils/constants/keyboardKeys'

import './DetailedClient.scss'

const DetailedClient: React.FC = () => {
  const [selectedPaymentId, setSelectedPaymentId] = useState(0)

  // unselect payment when press esc
  window.addEventListener(eventKeys.KEYDOWN, (e: KeyboardEvent) => {
    if (e.key === keyboardKeys.ESCAPE) {
      setSelectedPaymentId(0)
    }
  })

  return (
    <MainLayout>
      <div className="detailed_client wrapper">
        <DetailedClientLeft
          selectedPaymentId={selectedPaymentId}
          setSelectedPaymentId={setSelectedPaymentId}
        />
        <DetailedClientRight selectedPaymentId={selectedPaymentId} />
      </div>
    </MainLayout>
  )
}

export default DetailedClient
