import React from 'react'

import ClientsLeft from '../../components/clients/ClientsLeft'
import ClientsRight from '../../components/clients/ClientsRight'
import MainLayout from '../../layouts/MainLayout'

import './Clients.scss'

const Clients: React.FC = () => {
  return (
    <MainLayout>
      <div className="clients wrapper">
        <ClientsLeft />
        <ClientsRight />
      </div>
    </MainLayout>
  )
}

export default Clients
