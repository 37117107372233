import React from 'react'

const SelectIcon: React.FC = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9466 5.45333H7.79329H4.05329C3.41329 5.45333 3.09329 6.22667 3.54662 6.68L6.99995 10.1333C7.55329 10.6867 8.45329 10.6867 9.00662 10.1333L10.32 8.82L12.46 6.68C12.9066 6.22667 12.5866 5.45333 11.9466 5.45333Z"
        fill="#757D8A"
      />
    </svg>
  )
}

export default SelectIcon
