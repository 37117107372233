import { Grid, Popover } from 'antd'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import LoginIcon from '../../assets/svg/LoginIcon'
import LogoutIcon from '../../assets/svg/LogoutIcon'
import PrivateIcon from '../../assets/svg/PrivateIcon'
import PublicIcon from '../../assets/svg/PublicIcon'
import SelectIcon from '../../assets/svg/SelectIcon'
import SettingsIcon from '../../assets/svg/SettingsIcon'
import { useAppDispatch } from '../../hooks/redux'
import { userReducer } from '../../redux/reducers/userReducer'
import { localStorageKeys } from '../../utils/constants/localStorageKeys'
import { queryParamsKeys } from '../../utils/constants/queryParamsKeys'
import { isPrivate } from '../../utils/helpers/isPrivate'
import { rootPaths } from '../../utils/routes/rootPaths'
import SettingsModal from '../settingsModal/SettingsModal'

import './Header.scss'

const Header: React.FC = () => {
  const navigate = useNavigate()
  const { sm } = Grid.useBreakpoint()
  const [visibleSettingsModal, setVisibleSettingsModal] = useState(false)
  const dispatch = useAppDispatch()
  const { setAuthed } = userReducer.actions

  // change profile
  const changeProfile = () => {
    if (isPrivate()) {
      navigate(rootPaths.clients)
      navigate(0)
    } else {
      navigate(`${rootPaths.clients}?${queryParamsKeys.PRIVATE}=${true}`)
      navigate(0)
    }
  }

  // logout
  const logout = async () => {
    await localStorage.removeItem(localStorageKeys.AUTHORIZATION)
    await dispatch(setAuthed(false))
    navigate(rootPaths.index)
  }

  // popover ava charachter
  const popoverAvaChar = isPrivate() ? <PublicIcon /> : <PrivateIcon />

  // popover ava text
  const popoverAvaTxt = isPrivate() ? 'Biznes sahifa' : 'Shaxsiy sahifa'

  // user ava
  const userAva = isPrivate() ? (
    <>
      <span className="ava">
        <PrivateIcon />
      </span>
      {sm && <p>Shaxsiy sahifa</p>}
    </>
  ) : (
    <>
      <span className="ava">
        <PublicIcon />
      </span>
      {sm && <p>Biznes sahifa</p>}
    </>
  )

  // popover content
  const popoverContent = (
    <>
      <div className="user" onClick={changeProfile}>
        <div className="ava">
          <span>{popoverAvaChar}</span>
        </div>
        <p>{popoverAvaTxt}</p>
        <LoginIcon />
      </div>
      <div className="logout" onClick={logout}>
        <p>Chiqish</p>
        <LogoutIcon />
      </div>
    </>
  )

  // open settings modal
  const openSettingsModal = () => {
    setVisibleSettingsModal(true)
  }

  return (
    <div className="header">
      <div className="wrapper">
        <h1>Hamyonim</h1>
        <div className="header_right">
          <div className="settings" onClick={openSettingsModal}>
            <SettingsIcon />
          </div>
          <Popover
            content={popoverContent}
            trigger="click"
            overlayClassName="user_popover"
            placement="bottomLeft"
          >
            <div className="user">
              {userAva}
              <SelectIcon />
            </div>
          </Popover>
        </div>
      </div>
      <SettingsModal
        visible={visibleSettingsModal}
        setVisible={setVisibleSettingsModal}
      />
    </div>
  )
}

export default Header
