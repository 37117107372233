import { AutoComplete, Button, Form, Input } from 'antd'
import React, { memo, useEffect, useRef, useState } from 'react'

import DeleteIcon from '../../assets/svg/DeleteIcon'
import EditIcon from '../../assets/svg/EditIcon'
import SaveIcon from '../../assets/svg/SaveIcon'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { productReducer } from '../../redux/reducers/productReducer'
import { locales } from '../../utils/constants/locales'
import { units } from '../../utils/constants/units'
import { mathRound } from '../../utils/helpers/mathRound'
import { parseLocaledString } from '../../utils/helpers/parseLocaledString'
import { removeNaN } from '../../utils/helpers/removeNaN'
import { PaymentProductType } from '../../utils/models/PaymentProductType'

import './ProductCont.scss'

type props = {
  product: PaymentProductType | null
  index?: number
  clientCurrency: string | undefined
  viewMode: boolean
}

const ProductCont: React.FC<props> = ({
  product,
  index,
  clientCurrency,
  viewMode,
}) => {
  const { Item } = Form
  // const { Option } = Select
  // const [currency, setCurrency] = useState(currencies.USD)
  const [name, setName] = useState('')
  const [unit, setUnit] = useState('')
  const [quantity, setQuantity] = useState('')
  const [price, setPrice] = useState('')
  // const [currencyUsd, setCurrencyUsd] = useState(String(1))
  const [overallPrice, setOverallPrice] = useState('')
  // const { data: usdAmount } = useUsdAmount()
  const dispatch = useAppDispatch()
  const { products: paymentProducts } = useAppSelector(
    (state) => state.productReducer
  )
  const { addProduct, removeProduct, updateProduct } = productReducer.actions
  const nameInputRef = useRef<any>()
  const [enableUpdate, setEnableUpdate] = useState(false)

  // initial currency
  // useEffect(() => {
  //   if (clientCurrency) {
  //     setCurrency(clientCurrency)
  //   }
  // }, [clientCurrency])

  // product fields
  useEffect(() => {
    if (product) {
      setName(product.name)
      setUnit(product.unit)
      setQuantity(product.quantity?.toString())
      setPrice(product.price?.toLocaleString(locales.RU))
      setOverallPrice(product.overall_price?.toLocaleString(locales.RU))
      // setCurrency(product.currency)
      // setCurrencyUsd((product.currency_usd || 1)?.toLocaleString(locales.RU))
    }
  }, [product])

  // update product
  useEffect(() => {
    setEnableUpdate(false)
  }, [paymentProducts.length])

  // clear states
  const clearStates = () => {
    setName('')
    // setUnit('')
    setQuantity('')
    setPrice('')
    setOverallPrice('')
    // setCurrency(currencies.USD)
    // setCurrencyUsd(String(1))
  }

  // make enable update
  const makeEnableUpdate = () => {
    setEnableUpdate(true)
  }

  // is empty form
  const isEmptyForm = () => {
    return !name || !unit || !quantity || !price || !overallPrice
  }

  // handle currency
  // const handleCurrency = async (value: string) => {
  //   let newCurrencyUsd

  //   // check client currency
  //   if (clientCurrency === currencies.USD) {
  //     if (value === currencies.UZS) {
  //       newCurrencyUsd = mathRound(usdAmount || 1)?.toLocaleString(locales.RU)
  //     } else {
  //       newCurrencyUsd = String(1)
  //     }
  //   } else {
  //     if (value === currencies.USD) {
  //       newCurrencyUsd = mathRound(usdAmount || 1)?.toLocaleString(locales.RU)
  //     } else {
  //       newCurrencyUsd = String(1)
  //     }
  //   }

  //   setCurrencyUsd(newCurrencyUsd)
  //   overallPriceCounter(price, newCurrencyUsd, quantity)
  //   setCurrency(value)
  // }

  // make localed
  // const makeLocaled = (value: string) => {
  //   return (+removeNaN(value))?.toLocaleString(locales.RU)
  // }

  // overall price counter
  const overallPriceCounter = (
    price: string,
    // currencyUsd: string,
    quantity: string
  ) => {
    let newOverallPrice

    // check client currency
    // if (clientCurrency === currencies.UZS) {
    //   newOverallPrice = +removeNaN(price) * +removeNaN(currencyUsd) * +quantity
    // } else {
    //   newOverallPrice = (+removeNaN(price) / +removeNaN(currencyUsd)) * +quantity
    // }
    newOverallPrice = +removeNaN(price) * +removeNaN(quantity)

    setOverallPrice(mathRound(newOverallPrice).toLocaleString(locales.RU))
  }

  // handle name
  const handleName = (e: React.ChangeEvent<HTMLInputElement> | undefined) => {
    setName(e?.target.value || '')
  }

  // unit options
  const unitOptions = [
    { value: units.KG },
    { value: units.METR },
    { value: units.LITR },
  ]

  // handle unit
  const handleUnit = (value: string) => {
    setUnit(value)
  }

  // handle quantity
  const handleQuantity = (e: React.ChangeEvent<HTMLInputElement> | undefined) => {
    setQuantity(e?.target.value || '')

    overallPriceCounter(price, e?.target.value || ' ')
  }

  // handle price
  const handlePrice = (e: React.ChangeEvent<HTMLInputElement> | undefined) => {
    const price = removeNaN(e?.target.value || '')
    const arr = price.split('.')
    let newPrice = ''

    if (arr.length === 1) {
      newPrice = (+price).toLocaleString(locales.RU)
    } else if (arr.length === 2) {
      newPrice = (+arr[0]).toLocaleString(locales.RU) + '.' + arr[1]?.slice(0, 2)
    } else {
      newPrice = (+arr[0]).toLocaleString(locales.RU) + '.' + arr[1]
    }

    setPrice(newPrice)
    overallPriceCounter(newPrice, quantity)
  }

  // handle currency usd
  // const handleCurrencyUsd = (e: React.ChangeEvent<HTMLInputElement> | undefined) => {
  //   const newCurrencyUsd = makeLocaled(e?.target.value || '')

  //   setCurrencyUsd(newCurrencyUsd)

  //   overallPriceCounter(price, newCurrencyUsd, quantity)
  // }

  // new product
  const newProduct = {
    name,
    unit,
    // currency,
    // currency_usd: currencyUsd ? parseLocaledString(currencyUsd) : null,
    overall_price: parseLocaledString(overallPrice),
    price: parseLocaledString(price),
    quantity: parseLocaledString(quantity),
  }

  // add payment product
  const createProduct = () => {
    dispatch(addProduct(newProduct))
    clearStates()
    nameInputRef.current.focus()
  }

  // remove payment product
  const deleteProduct = () => {
    if (index !== undefined) {
      dispatch(removeProduct(index))
    }
  }

  // update payment product
  const editProduct = () => {
    if (index !== undefined && !isEmptyForm()) {
      dispatch(updateProduct({ product: newProduct, index }))
      setEnableUpdate(false)
    }
  }

  // input label
  const inputLabel = (label: string) => {
    if (viewMode && index === 0) {
      return label
    }
    if (!product) {
      return label
    }
    return ''
  }

  // disable input
  const disableInput = () => {
    if (!enableUpdate && product) {
      return true
    }
    return false
  }

  // on finish
  const onFinish = () => {
    if (product) {
      editProduct()
    } else {
      createProduct()
    }
  }

  // overall price label
  // const overallPriceLabel = () => {
  //   if (clientCurrency === currencies.USD) {
  //     return 'Jami (USD)'
  //   } else return 'Jami (UZS)'
  // }

  return (
    <div className="product_cont">
      <Form layout="vertical" onFinish={onFinish}>
        <Item label={inputLabel('Nomi')} className="name">
          <Input
            value={name}
            onChange={handleName}
            disabled={disableInput()}
            ref={nameInputRef}
          />
        </Item>
        <Item label={inputLabel('Miqdori')} className="quantity">
          <Input
            type="number"
            value={quantity}
            onChange={handleQuantity}
            disabled={disableInput()}
          />
        </Item>
        <Item label={inputLabel('Birligi')} className="unit">
          <AutoComplete
            options={unitOptions}
            value={unit}
            onChange={handleUnit}
            disabled={disableInput()}
          />
        </Item>
        <Item label={inputLabel('Narxi')} className="price">
          <Input value={price} onChange={handlePrice} disabled={disableInput()} />
        </Item>
        {/* <Item label={inputLabel('Valyuta')}>
          <Select
            value={currency}
            onChange={handleCurrency}
            disabled={disableInput()}
          >
            <Option value={currencies.UZS}>UZS</Option>
            <Option value={currencies.USD}>USD</Option>
          </Select>
        </Item> */}
        {/* <Item label={inputLabel('Dollar kursi')} className="currency_usd">
          {currency !== clientCurrency && (
            <Input
              value={currencyUsd}
              onChange={handleCurrencyUsd}
              disabled={disableInput()}
            />
          )}
        </Item> */}
        <Item label={inputLabel('Jami')} className="overall">
          <Input disabled value={overallPrice} />
        </Item>
        {!viewMode && (
          <Item className="submit">
            {product ? (
              <div className="action_cont">
                {enableUpdate ? (
                  <span className="cur_p" onClick={editProduct}>
                    <SaveIcon />
                  </span>
                ) : (
                  <span className="cur_p" onClick={makeEnableUpdate}>
                    <EditIcon />
                  </span>
                )}
                <span className="cur_p" onClick={deleteProduct}>
                  <DeleteIcon />
                </span>
              </div>
            ) : (
              <Button
                type="primary"
                htmlType="submit"
                onClick={createProduct}
                disabled={isEmptyForm()}
              >
                +
              </Button>
            )}
          </Item>
        )}
        {/* for working submit when press enter key */}
        <Button htmlType="submit" className="dn p0" />
      </Form>
    </div>
  )
}

export default memo(ProductCont)
