import React from 'react'

const PrivateIcon: React.FC = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 16.25C16.5899 16.25 19.5 13.3399 19.5 9.75C19.5 6.16015 16.5899 3.25 13 3.25C9.41015 3.25 6.5 6.16015 6.5 9.75C6.5 13.3399 9.41015 16.25 13 16.25Z"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M3.14844 21.9375C4.14673 20.208 5.58272 18.7718 7.31205 17.7733C9.04137 16.7748 11.0031 16.2491 13 16.2491C14.9969 16.2491 16.9586 16.7748 18.688 17.7733C20.4173 18.7718 21.8533 20.208 22.8516 21.9375"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default PrivateIcon
