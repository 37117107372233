import { localStorageKeys } from '../constants/localStorageKeys'

export const authService = {
  token: '',

  getToken(): string | null {
    if (this.token) {
      return this.token
    }
    return localStorage.getItem(localStorageKeys.AUTHORIZATION)
  },

  getUser(): { name: string; image: string; token: string } | null {
    const user = localStorage.getItem(localStorageKeys.USER) || ''

    return JSON.parse(user)
  },

  setUser(name: string, image: string) {
    localStorage.setItem(localStorageKeys.USER, JSON.stringify({ name, image }))
  },

  setToken(Authorization: string) {
    this.token = Authorization
    localStorage.setItem(localStorageKeys.AUTHORIZATION, Authorization)
  },

  isAuth(): boolean {
    return !!this.getToken()
  },
}
