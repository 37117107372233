import { addControllerPrefix } from '../helpers/addControllerPrefix'

const controllers = {
  auth: '/login',
  client: '/client',
  balance: '/balance',
  payment: '/payment',
  history: '/history',
  product: '/payment_product',
  user: '/user',
  excel: '/excel',
}

// auth
const auth = {
  INDEX: '',
}
export const authEndpoints: typeof auth = addControllerPrefix(auth, controllers.auth)

// client
const client = {
  INDEX: '',
}
export const clientEndpoints: typeof client = addControllerPrefix(
  client,
  controllers.client
)

// balance
const balance = {
  INDEX: '',
}
export const balanceEndpoints: typeof balance = addControllerPrefix(
  balance,
  controllers.balance
)

// payment
const payment = {
  INDEX: '',
}
export const paymentEndpoints: typeof payment = addControllerPrefix(
  payment,
  controllers.payment
)

// history
const history = {
  INDEX: '',
}
export const historyEndpoints: typeof history = addControllerPrefix(
  history,
  controllers.history
)

// product
const product = {
  INDEX: '',
}
export const productEndpoints: typeof product = addControllerPrefix(
  product,
  controllers.product
)

// user
const user = {
  INDEX: '',
}
export const userEndpoints: typeof user = addControllerPrefix(user, controllers.user)

// excel
const excel = {
  CLIENT: '/client',
  PAYMENT: '/payment',
}
export const excelEndpoints: typeof excel = addControllerPrefix(
  excel,
  controllers.excel
)
