import React from 'react'

import './PageNotFound.scss'

const PageNotFound: React.FC = () => {
  return (
    <div className="not_found">
      <h1>Sahifa topilmadi</h1>
    </div>
  )
}

export default PageNotFound
