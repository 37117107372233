import { Spin } from 'antd'
import React from 'react'
import { useParams } from 'react-router-dom'
import BalanceCard from '../../common/balanceCard/BalanceCard'
import { useBalance, usePaymentHistory } from '../../queries/queries'
import { isEmptyArr } from '../../utils/helpers/isEmptyArr'

type props = {
  selectedPaymentId: number
}

const DetailedClientRight: React.FC<props> = ({ selectedPaymentId }) => {
  const params = useParams()
  const { data } = useBalance(params?.id)
  const { data: historyData, isLoading: historyLoading } =
    usePaymentHistory(selectedPaymentId)

  // title
  const title = () => {
    if (!historyLoading) {
      if (!isEmptyArr(historyData || [])) {
        return 'Amallari tarixi'
      }
      return "Amallar tarixi yo'q"
    }
  }

  // statistic
  const statistic = () => {
    return data?.map((item) => <BalanceCard key={item.key} data={item} />)
  }

  // history of payment
  const history = () => {
    if (selectedPaymentId) {
      if (historyLoading) {
        return (
          <div className="df jc-c">
            <Spin />
          </div>
        )
      } else {
        return historyData?.map((item, index) => (
          <>
            <div className="history_card" key={index}>
              <p>{item.information}</p>
              <span>{item.date}</span>
            </div>
          </>
        ))
      }
    }
  }

  return (
    <div className="detailed_client_right">
      {statistic()}
      <h2 className="title">{title()}</h2>
      {history()}
    </div>
  )
}

export default DetailedClientRight
