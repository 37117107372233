import React from 'react'

const EditIcon: React.FC = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 14.6666H2C1.72667 14.6666 1.5 14.44 1.5 14.1666C1.5 13.8933 1.72667 13.6666 2 13.6666H14C14.2733 13.6666 14.5 13.8933 14.5 14.1666C14.5 14.44 14.2733 14.6666 14 14.6666Z"
        fill="#5A6474"
      />
      <path
        d="M12.68 2.31994C11.3867 1.02661 10.12 0.993275 8.79334 2.31994L7.98668 3.12661C7.92001 3.19328 7.89334 3.29994 7.92001 3.39328C8.42668 5.15994 9.84001 6.57328 11.6067 7.07994C11.6333 7.08661 11.66 7.09328 11.6867 7.09328C11.76 7.09328 11.8267 7.06661 11.88 7.01328L12.68 6.20661C13.34 5.55328 13.66 4.91994 13.66 4.27994C13.6667 3.61994 13.3467 2.97994 12.68 2.31994Z"
        fill="#5A6474"
      />
      <path
        d="M10.4067 7.68666C10.2133 7.59332 10.0267 7.49999 9.84668 7.39332C9.70001 7.30666 9.56001 7.21332 9.42001 7.11332C9.30668 7.03999 9.17334 6.93332 9.04668 6.82666C9.03334 6.81999 8.98668 6.77999 8.93334 6.72666C8.71334 6.53999 8.46668 6.29999 8.24668 6.03332C8.22668 6.01999 8.19334 5.97332 8.14668 5.91332C8.08001 5.83332 7.96668 5.69999 7.86668 5.54666C7.78668 5.44666 7.69334 5.29999 7.60668 5.15332C7.50001 4.97332 7.40668 4.79332 7.31334 4.60666C7.19097 4.34443 6.8468 4.26653 6.64218 4.47115L2.89334 8.21999C2.80668 8.30666 2.72668 8.47332 2.70668 8.58666L2.34668 11.14C2.28001 11.5933 2.40668 12.02 2.68668 12.3067C2.92668 12.54 3.26001 12.6667 3.62001 12.6667C3.70001 12.6667 3.78001 12.66 3.86001 12.6467L6.42001 12.2867C6.54001 12.2667 6.70668 12.1867 6.78668 12.1L10.5417 8.34496C10.7422 8.14444 10.6669 7.79941 10.4067 7.68666Z"
        fill="#5A6474"
      />
    </svg>
  )
}

export default EditIcon
