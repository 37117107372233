import { Button, Form, Input, Modal, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { LoadingOutlined } from '@ant-design/icons'

import CloseIcon from '../../assets/svg/CloseIcon'
import SettingsIcon from '../../assets/svg/SettingsIcon'
import { useUpdateProfile } from '../../queries/mutations'
import { useProfile } from '../../queries/queries'
import { omitKeys } from '../../utils/constants/omitKeys'
import { ProfilePayloadType } from '../../utils/models/ProfileType'

import './SettingsModal.scss'

type props = {
  visible: boolean
  setVisible: (visible: boolean) => void
}

type formFields = Omit<ProfilePayloadType, omitKeys.id>

const SettingsModal: React.FC<props> = ({ setVisible, visible }) => {
  const { Item } = Form
  const [formInstance] = Form.useForm()
  const [enableChangePassword, setEnableChangePassword] = useState(false)
  const updateProfileMutation = useUpdateProfile(setVisible)
  const { data, isLoading } = useProfile(visible)

  // initial form fields
  useEffect(() => {
    if (data) {
      formInstance.setFieldsValue({
        name: data.name,
        login: data.login,
      })
    }
  }, [data, formInstance])

  // close modal
  const closeModal = () => {
    setVisible(false)
  }

  // change password
  const changePassword = () => {
    setEnableChangePassword(true)
  }

  // on finish
  const onFinish = (fields: formFields) => {
    if (data?.id) {
      updateProfileMutation.mutate({ ...fields, id: data?.id })
    }
  }

  // on submit
  const onSubmit = () => {
    formInstance.submit()
  }

  // after close modal
  const afterClose = () => {
    formInstance.resetFields()
    setEnableChangePassword(false)
  }

  return (
    <Modal
      visible={visible}
      closable={false}
      onCancel={closeModal}
      centered
      className="custom_modal settings_modal"
      footer={null}
      afterClose={afterClose}
    >
      <div className="custom_modal_header">
        <h1 className="df ai_c">
          <SettingsIcon /> &nbsp; Profil sozlamalari &nbsp;
          {isLoading && <Spin size="small" indicator={<LoadingOutlined />} />}
        </h1>
        <div className="close_icon" onClick={closeModal}>
          <CloseIcon />
        </div>
      </div>
      <div className="settings_modal_body custom_modal_body">
        <Form layout="vertical" form={formInstance} onFinish={onFinish}>
          <Item
            name="name"
            label="Ism"
            rules={[{ required: true, message: 'Ismingizni kiriting!' }]}
          >
            <Input />
          </Item>
          <Item
            name="login"
            label="Login"
            rules={[
              { required: true, message: 'Loginingizni kiriting!' },
              {
                min: 5,
                message: "Login kamida 5 ta belgi bo'lishi kerak",
              },
            ]}
          >
            <Input />
          </Item>
          {!enableChangePassword && (
            <p className="change_password" onClick={changePassword}>
              Parolni o'zgartirish
            </p>
          )}
          {enableChangePassword && (
            <>
              <div className="fake_password">
                <Input.Password />
              </div>
              <Item
                name="current_password"
                label="Amaldagi parol"
                rules={[{ required: true, message: 'Amaldagi parolni kiriting!' }]}
              >
                <Input.Password />
              </Item>
              <Item
                name="password"
                label="Yangi parol"
                rules={[
                  { required: true, message: 'Yangi parolni kiriting!' },
                  {
                    min: 5,
                    message: "Parol kamida 5 ta belgi bo'lishi kerak",
                  },
                ]}
              >
                <Input.Password />
              </Item>
              <Item
                name="confirm_password"
                label="Yangi parolni tasdiqlang"
                rules={[
                  { required: true, message: 'Yangi parolni tasdiqlang!' },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve()
                      }
                      return Promise.reject(
                        new Error('Kiritgan parollaringiz mos kelmadi!')
                      )
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Item>
            </>
          )}
          {/* for working submit when press enter key */}
          <Button htmlType="submit" className="dn" />
        </Form>
      </div>
      <div className="custom_modal_footer">
        <Button className="cancel_btn" onClick={closeModal}>
          Bekor qilish
        </Button>
        <Button
          type="primary"
          className="submit_btn"
          htmlType="submit"
          onClick={onSubmit}
          loading={updateProfileMutation.isLoading}
        >
          Saqlash
        </Button>
      </div>
    </Modal>
  )
}

export default SettingsModal
