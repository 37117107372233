import React from 'react'
import { Route, Routes } from 'react-router-dom'
import PageNotFound from '../pages/404/PageNotFound'
import { rootRoutes } from '../utils/routes/rootRoutes'

const RootRouter: React.FC = () => {
  return (
    <Routes>
      {rootRoutes.map((route) => (
        <Route
          path={`${route.path}/*`}
          element={<route.element />}
          key={route.path}
        />
      ))}
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  )
}

export default RootRouter
