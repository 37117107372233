import React from 'react'

const SaveIcon: React.FC = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0396 7.39404L7.3708 10.894L5.53955 9.14404"
        stroke="#5A6474"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.28955 14.894C11.6033 14.894 14.2896 12.2078 14.2896 8.89404C14.2896 5.58033 11.6033 2.89404 8.28955 2.89404C4.97584 2.89404 2.28955 5.58033 2.28955 8.89404C2.28955 12.2078 4.97584 14.894 8.28955 14.894Z"
        stroke="#5A6474"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SaveIcon
