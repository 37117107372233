import React, { useEffect, useState } from 'react'
import DollarIcon from '../../assets/svg/DollarIcon'
import EyeIcon from '../../assets/svg/EyeIcon'
import EyeSlashIcon from '../../assets/svg/EyeSlashIcon'
import { balanceTypes } from '../../utils/constants/balanceTypes'
import { locales } from '../../utils/constants/locales'
import { BalanceType } from '../../utils/models/BalanceType'

import './BalanceCard.scss'

type props = {
  data: BalanceType
}

const BalanceCard: React.FC<props> = ({ data }) => {
  const [activeEye, setActiveEye] = useState(false)

  useEffect(() => {
    if (data?.client_currency) {
      setActiveEye(true)
    }
  }, [data?.client_currency])

  // classname
  const className = () => {
    switch (data?.type) {
      case balanceTypes.PLUS:
        return 'plus'
      case balanceTypes.MINUS:
        return 'minus'
      case balanceTypes.EQUAL:
        return 'equal'
    }
  }

  // change active eye
  const changeActiveEye = () => {
    setActiveEye(!activeEye)
  }

  return (
    <div className={`${className()} balance_card`}>
      <div className="balance_card_left">
        <DollarIcon />
      </div>
      {activeEye ? (
        <div className="detailed">
          {data?.value_usd ? (
            <p>
              {data?.value_usd?.toLocaleString(locales.RU)}&nbsp;
              <span>{data?.key_usd}</span>
            </p>
          ) : null}
          {data?.value_uzs ? (
            <p>
              {data?.value_uzs?.toLocaleString(locales.RU)}&nbsp;
              <span>{data?.key_uzs}</span>
            </p>
          ) : null}
          {!data?.value_usd && !data?.value_uzs && <p>0</p>}
        </div>
      ) : (
        <div className="balance_card_right">
          <div className="balance_card_right_title">{data?.key}</div>
          <p className="balance">
            <span className="dollar_icon">$</span>
            <span className="amount">{data?.value?.toLocaleString(locales.RU)}</span>
          </p>
        </div>
      )}
      {!data?.client_currency && data?.value ? (
        <div className="eye_icon" onClick={changeActiveEye}>
          {!activeEye ? <EyeSlashIcon /> : <EyeIcon />}
        </div>
      ) : null}
    </div>
  )
}

export default BalanceCard
