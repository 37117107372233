import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { sliceNames } from '../../utils/constants/sliceNames'
import { authService } from '../../utils/helpers/authService'

const initialState: {
  isAuth: boolean
  projectVersion: null | string
} = {
  isAuth: authService.isAuth(),
  projectVersion: null,
}

export const userReducer = createSlice({
  name: sliceNames.PRODUCTS,
  initialState,
  reducers: {
    setAuthed: (state, action: PayloadAction<boolean>) => {
      state.isAuth = action.payload
    },
    setProjectVersion: (state, action: PayloadAction<string>) => {
      state.projectVersion = action.payload
    },
  },
})

export default userReducer.reducer
