import { Grid } from 'antd'
import React from 'react'
import BalanceCard from '../../common/balanceCard/BalanceCard'

import { useBalance } from '../../queries/queries'

const ClientsRight: React.FC = () => {
  const { data } = useBalance()
  const { sm } = Grid.useBreakpoint()

  return (
    <div className="clients_right">
      {sm && <h2 className="title">Statistika</h2>}
      {data?.map((item) => (
        <BalanceCard key={item.key} data={item} />
      ))}
    </div>
  )
}

export default ClientsRight
