import Clients from '../../pages/clients/Clients'
import DetailedClient from '../../pages/detailedClient/DetailedClient'
import Login from '../../pages/login/Login'
import Redirector from '../../routing/Redirector'
import { rootPaths } from './rootPaths'

export const rootRoutes = [
  {
    path: rootPaths.login,
    element: Login,
  },
  {
    path: rootPaths.index,
    element: Redirector,
  },
  {
    path: rootPaths.clients,
    element: Clients,
  },
  {
    path: rootPaths.detailedClient,
    element: DetailedClient,
  },
]
