import { Button, Form, Modal, Select } from 'antd'
import React from 'react'
import CloseIcon from '../../assets/svg/CloseIcon'
import { useClientsExcel } from '../../queries/mutations'
import { currencies } from '../../utils/constants/currencies'

type props = {
  visible: boolean
  setVisible: (visible: boolean) => void
}

type formFields = {
  currency: string
  balance: string
}

const { Item } = Form
const { Option } = Select

const ClientsExcelModal: React.FC<props> = ({ setVisible, visible }) => {
  const downloadExcel = useClientsExcel(setVisible)
  const [formInstance] = Form.useForm()
  const { USD, UZS } = currencies

  // handle cancel
  const handleCancel = () => {
    setVisible(false)
  }

  // handle submit
  const handleSubmit = () => {
    formInstance.submit()
  }

  // after close modal
  const afterClose = () => {
    formInstance.resetFields()
  }

  // on finish
  const onFinish = (fields: formFields) => {
    downloadExcel.mutate(fields)
  }

  return (
    <Modal
      visible={visible}
      closable={false}
      onCancel={handleCancel}
      centered
      className="custom_modal clients_excel_modal"
      footer={null}
      afterClose={afterClose}
    >
      <div className="custom_modal_header">
        <h1>Excel</h1>
        <div className="close_icon" onClick={handleCancel}>
          <CloseIcon />
        </div>
      </div>
      <div className="custom_modal_body">
        <Form layout="vertical" form={formInstance} onFinish={onFinish}>
          <Item name="currency" label="To'lov turi" initialValue={null}>
            <Select>
              <Option value={null}>Barchasi</Option>
              <Option value={UZS}>{UZS.toUpperCase()}</Option>
              <Option value={USD}>{USD.toUpperCase()}</Option>
            </Select>
          </Item>
          <Item name="balance" label="Balans" initialValue={null}>
            <Select>
              <Option value={null}>Barchasi</Option>
              <Option value=">">Qarzlarim</Option>
              <Option value="<">Qarzdorlarim</Option>
            </Select>
          </Item>
        </Form>
      </div>
      <div className="custom_modal_footer">
        <Button className="cancel_btn" onClick={handleCancel}>
          Bekor qilish
        </Button>
        <Button
          type="primary"
          className="submit_btn"
          htmlType="submit"
          onClick={handleSubmit}
          loading={downloadExcel.isLoading}
        >
          Yuklash
        </Button>
      </div>
    </Modal>
  )
}

export default ClientsExcelModal
