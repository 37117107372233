import React from 'react'

const PublicIcon: React.FC = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.9375 16.25C11.8543 16.25 14.2188 13.8855 14.2188 10.9688C14.2188 8.052 11.8543 5.6875 8.9375 5.6875C6.02075 5.6875 3.65625 8.052 3.65625 10.9688C3.65625 13.8855 6.02075 16.25 8.9375 16.25Z"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M15.7827 5.88047C16.2496 5.75385 16.731 5.68897 17.2147 5.6875C18.6154 5.6875 19.9587 6.24392 20.9492 7.23434C21.9396 8.22477 22.496 9.56808 22.496 10.9688C22.496 12.3694 21.9396 13.7127 20.9492 14.7032C19.9587 15.6936 18.6154 16.25 17.2147 16.25"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.625 20.0484C2.44963 18.875 3.54457 17.9172 4.81732 17.256C6.09007 16.5948 7.50325 16.2496 8.9375 16.2496C10.3717 16.2496 11.7849 16.5948 13.0577 17.256C14.3304 17.9172 15.4254 18.875 16.25 20.0484"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.2148 16.25C18.6492 16.2491 20.0627 16.5939 21.3356 17.2551C22.6084 17.9162 23.7033 18.8744 24.5273 20.0484"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default PublicIcon
