import { useMutation, useQueryClient } from '@tanstack/react-query'
import { message } from 'antd'
import { FormInstance } from 'antd/es/form/Form'
import { stringify } from 'query-string'
import { useAppDispatch } from '../hooks/redux'
import { productReducer } from '../redux/reducers/productReducer'
import { userReducer } from '../redux/reducers/userReducer'
import $api from '../request/axiosInstance'
import {
  authEndpoints,
  clientEndpoints,
  excelEndpoints,
  paymentEndpoints,
  productEndpoints,
  userEndpoints,
} from '../utils/constants/api'
import { queryParamsKeys } from '../utils/constants/queryParamsKeys'
import { responseTypes } from '../utils/constants/responseTypes'
import { authService } from '../utils/helpers/authService'
import { downloadFile } from '../utils/helpers/downloadFile'
import { PaymentPayloadType } from '../utils/models/PaymentType'
import { ProfilePayloadType } from '../utils/models/ProfileType'
import { queryNames } from './queryNames'

// login
export function useLogin(formInstance: FormInstance) {
  const dispatch = useAppDispatch()
  const { setAuthed } = userReducer.actions

  return useMutation(
    async (auth: { login: string; password: string }) => {
      const res = await $api.post(authEndpoints.INDEX, {
        login: auth.login,
        password: auth.password,
      })
      return res.data
    },
    {
      onSuccess: (res) => {
        dispatch(setAuthed(true))
        authService.setToken(res?.Authorization)
      },
      onError: () => {
        formInstance.resetFields()
        message.error("Login yoki parol noto'g'ri kiritildi!")
      },
    }
  )
}

// create client
export function useCreateClient(setVisibleModal: (visible: boolean) => void) {
  const qc = useQueryClient()

  return useMutation(
    async (client: {
      name: string
      phone: string
      phone_1?: string
      description: string
      currency: string
    }) => {
      const res = await $api.post(clientEndpoints.INDEX, client)
      return res.data
    },
    {
      onSuccess: () => {
        qc.invalidateQueries([queryNames.CLIENTS])
        message.success('Mijoz yaratildi!')
        setVisibleModal(false)
      },
      onError: () => {
        message.error('Mijoz yaratishda xatolik!')
      },
    }
  )
}

// update client
export function useUpdateClient(setVisibleModal: (visible: boolean) => void) {
  const qc = useQueryClient()

  return useMutation(
    async (client: {
      name: string
      phone: string
      description: string
      currency: string
      id: number
      currency_usd: number
      client_balance: number
    }) => {
      const res = await $api.put(clientEndpoints.INDEX, client)
      return res.data
    },
    {
      onSuccess: () => {
        qc.invalidateQueries([queryNames.CLIENTS])
        message.success('Mijoz tahrirlandi!')
        setVisibleModal(false)
      },
      onError: () => {
        message.error('Mijoz tahrirlashda xatolik!')
      },
    }
  )
}

// create payment
export function useCreatePayment(setVisibleModal: (visible: boolean) => void) {
  const qc = useQueryClient()

  return useMutation(
    async (payment: PaymentPayloadType) => {
      const res = await $api.post(paymentEndpoints.INDEX, payment)
      return res.data
    },
    {
      onSuccess: () => {
        qc.invalidateQueries([queryNames.PAYMENTS])
        qc.invalidateQueries([queryNames.BALANCE])
        message.success("To'lov yaratildi!")
        setVisibleModal(false)
      },
      onError: () => {
        message.error("To'lov yaratishda xatolik!")
      },
    }
  )
}

// update payment
export function useUpdatePayment(setVisibleModal: (visible: boolean) => void) {
  const qc = useQueryClient()

  return useMutation(
    async (payment: PaymentPayloadType) => {
      const res = await $api.put(paymentEndpoints.INDEX, payment)
      return res.data
    },
    {
      onSuccess: () => {
        qc.invalidateQueries([queryNames.PAYMENTS])
        qc.invalidateQueries([queryNames.BALANCE])
        message.success("To'lov tahrirlandi!")
        setVisibleModal(false)
      },
      onError: () => {
        message.error("To'lov tahrirlashda xatolik!")
      },
    }
  )
}

// delete payment
export function useDeletePayment() {
  const qc = useQueryClient()

  return useMutation(
    async (paymentId: number) => {
      const res = await $api.delete(
        `${paymentEndpoints.INDEX}?${queryParamsKeys.ID}=${paymentId}`
      )
      return res.data
    },
    {
      onSuccess: () => {
        qc.invalidateQueries([queryNames.PAYMENTS])
        qc.invalidateQueries([queryNames.BALANCE])
        message.success("To'lov o'chirildi!")
      },
      onError: () => {
        message.error("To'lov o'chirishda xatolik!")
      },
    }
  )
}

// get payment products
export function usePaymentProducts() {
  const dispatch = useAppDispatch()
  const { setProducts } = productReducer.actions

  return useMutation(
    async (paymentId: number) => {
      const res = await $api.get(
        `${productEndpoints.INDEX}?${queryParamsKeys.PAYMENT_ID}=${paymentId}`
      )

      dispatch(setProducts(res.data))
    },
    {
      onError: () => {
        message.error("To'lov mahsulotlarini olishda xatolik!")
      },
    }
  )
}

// update profile
export function useUpdateProfile(setVisibleModal: (visible: boolean) => void) {
  return useMutation(
    async (profileData: ProfilePayloadType) => {
      const res = await $api.put(userEndpoints.INDEX, profileData)
      return res.data
    },
    {
      onSuccess: () => {
        message.success("Foydalanuvchi ma'lumotlari o'zgartirildi!")
        setVisibleModal(false)
      },
      onError: () => {
        message.error("Foydalanuvchi ma'lumotlarini o'zgartirishda xatolik!")
      },
    }
  )
}

// clients excel
export function useClientsExcel(setVisiblePopover: (visible: boolean) => void) {
  return useMutation(
    async ({ currency, balance }: { currency: string; balance: string }) => {
      let url = `${excelEndpoints.CLIENT}?`

      if (currency) {
        url += `${stringify({ currency })}`
      }

      if (balance) {
        url += `&${stringify({ balance })}`
      }

      const res = await $api.get(url, {
        responseType: responseTypes.BLOB,
      })
      return res.data
    },
    {
      onSuccess: (res) => {
        setVisiblePopover(false)
        downloadFile(res, 'Mijozlar.xlsx')
      },
      onError: () => {
        message.error('Excel yuklab olishda xatolik!')
      },
    }
  )
}

// clients excel
export function usePaymentsExcel(
  setVisiblePopover: (visible: boolean) => void,
  clientName: string | undefined,
  clientId: string | undefined
) {
  return useMutation(
    async (paymentType: string) => {
      let url = `${excelEndpoints.PAYMENT}?${queryParamsKeys.CLIENT_ID}=${clientId}`

      if (paymentType) {
        url += `&${stringify({ type: paymentType })}`
      }

      const res = await $api.get(url, {
        responseType: responseTypes.BLOB,
      })
      return res.data
    },
    {
      onSuccess: (res) => {
        setVisiblePopover(false)
        downloadFile(res, `${clientName || 'Mijoz'}.xlsx`)
      },
      onError: () => {
        message.error('Excel yuklab olishda xatolik!')
      },
    }
  )
}
