import React from 'react'
import { Navigate } from 'react-router-dom'
import { useAppSelector } from '../hooks/redux'

import { rootPaths } from '../utils/routes/rootPaths'

const Redirector: React.FC = () => {
  let page
  const isAuth = useAppSelector((state) => state.userReducer.isAuth)

  // checking page
  if (window.location.pathname === rootPaths.login) {
    if (isAuth) {
      page = rootPaths.clients
    }
  } else if (window.location.pathname === rootPaths.index) {
    if (isAuth) {
      page = rootPaths.clients
    } else {
      page = rootPaths.login
    }
  } else {
    if (!isAuth) {
      page = rootPaths.login
    }
  }

  if (page) {
    return <Navigate to={page} />
  }

  return null
}

export default Redirector
