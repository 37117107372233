import { htmlElements } from '../constants/htmElements'

export const downloadFile = (file: File, fileName: string) => {
  const downloadUrl = window.URL.createObjectURL(new Blob([file]))
  const link = document.createElement(htmlElements.LINK)
  link.href = downloadUrl
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
  link.remove()
}
