import React from 'react'

const CloseIcon: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z"
        fill="#DFDFE6"
      />
      <path
        d="M9.17011 15.58C8.98011 15.58 8.79011 15.51 8.64011 15.36C8.35011 15.07 8.35011 14.59 8.64011 14.3L14.3001 8.64C14.5901 8.35 15.0701 8.35 15.3601 8.64C15.6501 8.93 15.6501 9.41 15.3601 9.7L9.70011 15.36C9.56011 15.51 9.36011 15.58 9.17011 15.58Z"
        fill="#3D3D3D"
      />
      <path
        d="M14.8301 15.58C14.6401 15.58 14.4501 15.51 14.3001 15.36L8.64011 9.7C8.35011 9.41 8.35011 8.93 8.64011 8.64C8.93011 8.35 9.41011 8.35 9.70011 8.64L15.3601 14.3C15.6501 14.59 15.6501 15.07 15.3601 15.36C15.2101 15.51 15.0201 15.58 14.8301 15.58Z"
        fill="#3D3D3D"
      />
    </svg>
  )
}

export default CloseIcon
